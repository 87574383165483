import { Model } from 'objectmodel'
import Applicant from './Applicant'

export default Model({
  id: Number,
  applicant: Applicant,
  createdAt: String,
  processedAt: [String],
  recruiter: [Number],
  stage: [Number, String],
  vacancy: String,
  read: Boolean
})
