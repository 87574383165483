import Department from '~/model/Department'
import DepartmentSerializer from '~/serializer/Department'

export default {
  async get() {
    const response = await this.$axios.$get('/departments/')
    return response?.results?.map((el) => Department(DepartmentSerializer(el))) || []
  },
  add({ title }) {
    return this.$axios.$post('/departments/', {
      title
    })
  },
  patch({ id, data }) {
    return this.$axios.$patch(`/departments/${id}/`, data)
  }
}
