import { render, staticRenderFns } from "./default-new.vue?vue&type=template&id=b0cf3234&scoped=true&"
import script from "./default-new.vue?vue&type=script&lang=js&"
export * from "./default-new.vue?vue&type=script&lang=js&"
import style0 from "./default-new.vue?vue&type=style&index=0&id=b0cf3234&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0cf3234",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Sidebar: require('/app/components/Sidebar/Sidebar.vue').default,GlobalTools: require('/app/components/GlobalTools/GlobalTools.vue').default})
