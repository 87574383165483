import Applicant from './Applicant'

export default function (el = {}) {
  return {
    id: el.id,
    applicant: Applicant(el.applicant),
    createdAt: el.created_at,
    processedAt: el.processed_at,
    recruiter: el.recruiter,
    stage: el.stage,
    vacancy: el?.vacancy.title,
    read: el.is_read
  }
}
