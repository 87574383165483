import Recruiter from '~/model/Recruiter'
import RecruiterSerializer from '~/serializer/Recruiter'

export default {
  async get() {
    const response = await this.$axios.$get('/users/')
    return response?.results?.map((el) => Recruiter(RecruiterSerializer(el))) || []
  },
  async me() {
    return Recruiter(RecruiterSerializer((await this.$axios.$get('/users/me/')) || {}))
  }
}
