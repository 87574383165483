//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Button } from '05-ui-kit'
export default {
  name: 'JobHeader',
  components: {
    Button
  },
  data() {
    return {
      openMenu: false
    }
  },
  mounted() {
    window.addEventListener(
      'click',
      (e) => {
        if (!e.target.closest('.menu') && !e.target.closest('.header__menu-open')) {
          this.openMenu = false
        }
      },
      { passive: true }
    )
  }
}
