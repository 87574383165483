export const state = () => ({
  confirmation: {
    active: false,
    title: null,
    description: null
  }
})

export const mutations = {
  enableConfirmation(state, { title, description } = {}) {
    state.confirmation.title = title
    state.confirmation.description = description
    state.confirmation.active = true
  },
  disableConfirmation(state) {
    state.confirmation.title = null
    state.confirmation.description = null
    state.confirmation.active = false
  }
}
