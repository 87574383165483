import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _a399f0f4 = () => interopDefault(import('../pages/job/index.vue' /* webpackChunkName: "pages/job/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _15f96ec1 = () => interopDefault(import('../pages/merge/index.vue' /* webpackChunkName: "pages/merge/index" */))
const _2876d73c = () => interopDefault(import('../pages/people/index.vue' /* webpackChunkName: "pages/people/index" */))
const _309e3402 = () => interopDefault(import('../pages/quiz/index.vue' /* webpackChunkName: "pages/quiz/index" */))
const _84c92402 = () => interopDefault(import('../pages/test/index.vue' /* webpackChunkName: "pages/test/index" */))
const _1be3c8a2 = () => interopDefault(import('../pages/vacancies/index.vue' /* webpackChunkName: "pages/vacancies/index" */))
const _c6d84ef8 = () => interopDefault(import('../pages/demo/people/index.vue' /* webpackChunkName: "pages/demo/people/index" */))
const _5dca316c = () => interopDefault(import('../pages/demo/quiz/index.vue' /* webpackChunkName: "pages/demo/quiz/index" */))
const _573be25a = () => interopDefault(import('../pages/demo/vacancies/index.vue' /* webpackChunkName: "pages/demo/vacancies/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard"
  }, {
    path: "/job",
    component: _a399f0f4,
    name: "job"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/merge",
    component: _15f96ec1,
    name: "merge"
  }, {
    path: "/people",
    component: _2876d73c,
    name: "people"
  }, {
    path: "/quiz",
    component: _309e3402,
    name: "quiz"
  }, {
    path: "/test",
    component: _84c92402,
    name: "test"
  }, {
    path: "/vacancies",
    component: _1be3c8a2,
    name: "vacancies"
  }, {
    path: "/demo/people",
    component: _c6d84ef8,
    name: "demo-people"
  }, {
    path: "/demo/quiz",
    component: _5dca316c,
    name: "demo-quiz"
  }, {
    path: "/demo/vacancies",
    component: _573be25a,
    name: "demo-vacancies"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
