//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      nav: [
        { title: 'Анкеты', id: 'resume', link: '/demo/people' },
        { title: 'Вакансии', id: 'vacancy', link: '/demo/vacancies' },
        { title: 'Дела', id: 'deal', link: '#' },
        { title: 'Тесты', id: 'quiz', link: '/demo/quiz' },
        { title: 'Аналитика', id: 'analytics', link: '#' },
        { title: 'Настройки', id: 'settings', link: '#' },
        { title: 'Интеграции', id: 'integration', link: '#' }
      ],
      isCollapse: false
    }
  },
  methods: {
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
      this.$cookies.set('isCollapseSidebar', this.isCollapse)
    }
  },
  created() {
    if (this.$cookies.get('isCollapseSidebar')) this.isCollapse = this.$cookies.get('isCollapseSidebar')
  }
}
