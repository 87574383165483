import { Model } from 'objectmodel'

export default Model({
  id: Number,
  birthday: [String],
  createdAt: [String],
  name: [String],
  lead: [String],
  note: [String],
  job: [String],
  phone: [String],
  recruiter: [String],
  result: [String],
  source: [String],
  test: [String],
  vacancy: [String]
})
