export default {
  login({ email, password }) {
    return this.$axios.$post(
      '/auth/login/',
      {
        email,
        password
      },
      {
        skipAuth: true
      }
    )
  },
  refreshToken({ refresh }) {
    return this.$axios.$post(
      '/auth/token/refresh/',
      {
        refresh
      },
      {
        skipAuth: true
      }
    )
  }
}
