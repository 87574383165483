import { Model, ArrayModel } from 'objectmodel'
import Recruiter from './Recruiter'
import City from './City'
import { PositiveInteger } from './basic'

export default Model({
  id: Number,
  stage: Number,
  department: {
    id: Number,
    name: String
  },
  city: City,
  employmentType: [String],
  isOpen: Boolean,
  note: [String],
  recruiter: [ArrayModel([Recruiter])],
  salary: [String],
  schedule: [String],
  name: String,
  applicantCount: [PositiveInteger]
})
