import Applicant from './Applicant'

export default function (el = {}) {
  return {
    id: el.id,
    duplicate: Applicant(el.duplicate),
    percent: el.percent,
    recruiter: el?.recruiters?.map((item) => item.recruiter__first_name).join(', '),
    applicant: el.applicant
  }
}
