//
//
//
//
//
//
//

export default {
  errorCaptured(e) {
    console.log('error was captured', e)
  }
}
