import auth from './auth.api'
import applicant from './applicant.api'
import stage from './stage.api'
import department from './department.api'
import vacancy from './vacancy.api'
import application from './application.api'
import attachment from './attachment.api'
import source from './source.api'
import user from './user.api'
import cases from './case.api'
import caseAttachment from './case-attachment.api'
import event from './event.api'
import city from './city.api'

const mapBindMethods = (context, methods) => {
  const obj = {}
  Object.keys(methods).forEach((key) => {
    obj[key] = methods[key].bind(context)
  })
  return obj
}

export default (context) => ({
  auth: mapBindMethods(context, auth),
  applicant: mapBindMethods(context, applicant),
  stage: mapBindMethods(context, stage),
  department: mapBindMethods(context, department),
  vacancy: mapBindMethods(context, vacancy),
  application: mapBindMethods(context, application),
  attachment: mapBindMethods(context, attachment),
  source: mapBindMethods(context, source),
  user: mapBindMethods(context, user),
  cases: mapBindMethods(context, cases),
  caseAttachment: mapBindMethods(context, caseAttachment),
  event: mapBindMethods(context, event),
  city: mapBindMethods(context, city)
})
