//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'GlobalTools',
  computed: {
    ...mapState({
      confirmation: (state) => state.modal.confirmation
    })
  }
}
