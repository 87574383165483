//
//
//
//
//
//
//
//
//
//
//
//
//

import { Button, Modal } from '05-ui-kit'

export default {
  components: {
    Button,
    Modal
  },
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: 'Подтвердите действие'
    },
    isShow: {
      type: Boolean,
      default: false
    }
  }
}
