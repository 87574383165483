export default (context, inject) => {
  const id = context.$config.yandexMetrikaId
  if (id && process.env.NODE_ENV === 'production') {
    ;(function (m, e, t, r, i, k, a) {
      m[i] =
        m[i] ||
        function () {
          ;(m[i].a = m[i].a || []).push(arguments)
        }
      m[i].l = 1 * new Date()
      ;(k = e.createElement(t)), (a = e.getElementsByTagName(t)[0]), (k.async = 1), (k.src = r), a.parentNode.insertBefore(k, a)
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')

    ym(id, 'init', {
      defer: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      trackHash: true
    })

    inject('metrika', {
      hit: (url) => {
        ym(id, 'hit', url)
      }
    })
  } else {
    inject('metrika', {
      hit: () => {}
    })
  }
}
