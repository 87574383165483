//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'UserAuth',
  props: {
    isNetwork: {
      type: Boolean,
      default: false
    },
    isNotification: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      name: (state) => state?.user?.data?.name
    })
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout')
    }
  }
}
