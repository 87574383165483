import { serializeQueryParams } from './helper'
import Stage from '~/model/Stage'
import StageSerializer from '~/serializer/Stage'

export default {
  async get({ limit = 999 } = {}) {
    const response = await this.$axios.$get(
      `/stages/${serializeQueryParams({
        limit
      })}`
    )
    return response?.results?.map((el) => Stage(StageSerializer(el))) || []
  },
  add({ title }) {
    return this.$axios.$post('/stages/', {
      title
    })
  },
  patch({ id, data }) {
    return this.$axios.$patch(`/stages/${id}/`, data)
  }
}
