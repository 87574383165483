import { Model } from 'objectmodel'
import Duplication from './Duplication '

export default Model({
  id: Number,
  duplicate: Duplication,
  percent: String,
  recruiter: [String],
  applicant: Number
})
