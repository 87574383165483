import { serializeQueryParams } from './helper'
import Vacancy from '~/model/Vacancy'
import VacancySerializer from '~/serializer/Vacancy'
import Sourse from '~/model/Sourse'
import SourseSerializer from '~/serializer/Sourse'
import Application from '~/model/Application'
import ApplicationSerializer from '~/serializer/Application'

export default {
  async get({ order, stage, department, vacancy, limit, search, offset, recruiter, blacklist, archived, read } = {}) {
    const response = await this.$axios.$get(
      `/applications/${serializeQueryParams({
        order,
        stage,
        department,
        vacancy,
        limit,
        search,
        offset,
        recruiter,
        is_in_blacklist: blacklist,
        is_archived: archived,
        is_read: read
      })}`
    )

    const countNew = (
      await this.$axios.$get(
        `/applications/${serializeQueryParams({
          limit: 1,
          offset: 0,
          is_read: false,
          is_in_blacklist: false,
          is_archived: false
        })}`
      )
    ).count

    return {
      countNew,
      count: response.count,
      results: response?.results.map((el) => Application(ApplicationSerializer(el))) || []
    }
  },
  getById({ id } = {}) {
    return this.$axios.$get(`/applications/${id}/`)
  },
  read({ id } = {}) {
    return this.$axios.$post(`/applications/${id}/read/`)
  },
  patch({ id, data }) {
    return this.$axios.$patch(`/applications/${id}/`, data)
  },
  remove({ id } = {}) {
    return this.$axios.$delete(`/applications/${id}/`)
  },
  changeStageAll({ items, val } = {}) {
    items.forEach((item) => {
      return this.$axios.$patch(`/applications/${item.id}/`, { stage: val })
    })
  },
  createattachments({ file, to_user } = {}) {
    return this.$axios.$post(
      `/attachments/`,
      { file: file, to_user },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  async getFourRequest() {
    return {
      vacancy: (await this.$axios.$get('/vacancies/'))?.results.map((el) => Vacancy(VacancySerializer(el))) || [],
      source: (await this.$axios.$get('/sources/'))?.results.map((el) => Sourse(SourseSerializer(el))) || []
    }
  }
}
