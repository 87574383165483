import Department from './Department'

export default function (el = {}) {
  return {
    id: el.id,
    amount: el.applicants_amount,
    name: el.title,
    department: el?.departments?.map((item) => Department(item)) || []
  }
}
