export const state = () => ({
  isPrint: false
})

export const mutations = {
  enable(state) {
    state.isPrint = true
  },
  disable(state) {
    state.isPrint = false
  }
}
