import { Model, ArrayModel } from 'objectmodel'
import Study from './Study'
import Work from './Work'
import Duplicate from './Duplicate'
import Sourse from './Sourse'

export default Model({
  id: Number,
  birthday: [String],
  createdAt: String,
  currentAddress: [String],
  registrationAddress: [String],
  email: [String],
  name: String,
  note: [String],
  phone: String,
  employmentType: [Number],
  bio: [String],
  job: [String],
  source: [Sourse],
  study: [ArrayModel([Study])],
  work: [ArrayModel([Work])]
})
