import { getFormData, serializeQueryParams } from './helper'
import Attachment from '~/model/Attachment'
import AttachmentSerializer from '~/serializer/Attachment'

export default {
  async get({ to } = {}) {
    const response = await this.$axios.$get(`/attachments/${serializeQueryParams({ to })}`)
    return {
      results: response?.results.map((el) => Attachment(AttachmentSerializer(el))) || []
    }
  },
  getById({ id } = {}) {
    return this.$axios.$get(`/attachments/${id}/`)
  },
  createattachments({ file, to } = {}) {
    return this.$axios.$post(`/attachments/`, getFormData({ file: file, to }), {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })
  },
  remove({ id } = {}) {
    return this.$axios.$delete(`/attachments/${id}/`)
  }
}
