import { getFormData, serializeQueryParams } from './helper'
import Attachment from '~/model/Attachment'
import AttachmentSerializer from '~/serializer/Attachment'

export default {
  async get({ cases } = {}) {
    const response = await this.$axios.$get(`/case_attachments/${serializeQueryParams({ cases })}`)
    return {
      results: response?.results.map((el) => Attachment(AttachmentSerializer(el))) || []
    }
  },
  getById({ id } = {}) {
    return this.$axios.$get(`/case_attachments/${id}/`)
  },
  createattachments({ file, cases } = {}) {
    return this.$axios.$post(`/case_attachments/`, getFormData({ file: file, cases }), {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })
  },
  remove({ id } = {}) {
    return this.$axios.$delete(`/case_attachments/${id}/`)
  }
}
