import { render, staticRenderFns } from "./GlobalTools.vue?vue&type=template&id=370248f0&scoped=true&"
import script from "./GlobalTools.vue?vue&type=script&lang=js&"
export * from "./GlobalTools.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "370248f0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConfirmationModal: require('/app/components/modal/RemoveModal/ConfirmationModal.vue').default})
