import Vacancy from './Vacancy'

export default function (el = {}) {
  return {
    id: el.id,
    amount: el.applicants_amount,
    name: el.title,
    vacancy: el?.vacancies?.map((item) => Vacancy(item)) || []
  }
}
