import { serializeQueryParams } from './helper'
import Case from '~/model/Case'
import CaseSerializer from '~/serializer/Case'

export default {
  async get({ limit = 50, search, order, offset } = {}) {
    const response = await this.$axios.$get(
      `/cases/${serializeQueryParams({
        limit,
        search,
        order,
        offset
      })}`
    )

    return {
      count: response.count,
      results: response?.results.map((el) => Case(CaseSerializer(el))) || []
    }
  },
  getById({ id } = {}) {
    return this.$axios.$get(`/cases/${id}/`)
  },
  patch({ id, data } = {}) {
    return this.$axios.$patch(`/cases/${id}/`, data)
  },
  put({ id, data } = {}) {
    return this.$axios.$put(`/cases/${id}/`, data)
  }
}
