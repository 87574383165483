import { serializeQueryParams, getFormData } from './helper'

export default {
  get({ search } = {}) {
    return this.$axios.$get(
      `/applicants/${serializeQueryParams({
        search
      })}`
    )
  },
  getById({ id } = {}) {
    return this.$axios.$get(`/applicants/${id}/`)
  },
  patch({ id, data } = {}) {
    return this.$axios.$patch(`/applicants/${id}/`, data)
  },
  create({ data } = {}) {
    return this.$axios.$post(`/applicants/`, getFormData(data), {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })
  },
  put({ id, data } = {}) {
    return this.$axios.$put(`/applicants/${id}/`, data)
  },
  getEvents({ applicant_pk, limit, offset } = {}) {
    return this.$axios.$get(
      `/applicants/${applicant_pk}/event/${serializeQueryParams({
        limit,
        offset
      })}`
    )
  },
  remove({ id } = {}) {
    return this.$axios.$delete(`/applicants/${id}/`)
  },
  async removeAll(selected) {
    const promises = await selected.map((item) => this.$axios.$patch(`/applicants/${item.applicant.id}/`, { is_archived: true }))
    return Promise.all(promises)
  }
}
