export const state = () => ({
  items: null
})

export const mutations = {
  setItems(state, data) {
    state.items = data
  },
  addItem(state, data) {
    state.items.push(data)
  },
  recalculateItems(state, { before, after } = {}) {
    let beforeObj = state.items[state.items.findIndex((el) => +el.id === before)]
    let afterObj = state.items[state.items.findIndex((el) => +el.id === after)]
    afterObj.amount++
    beforeObj.amount--
    if (state?.department) {
      let beforeObjDepartments = beforeObj.department.find((el) => el.id === state.department)
      beforeObjDepartments.amount--
      if (beforeObjDepartments.amount == 0) {
        beforeObj.department = beforeObj.department.filter((el) => el.id != beforeObjDepartments.id)
      } else if (state?.vacancy) {
        let beforeObjVacancy = beforeObjDepartments.vacancy.find((el) => el.id === state.vacancy)
        beforeObjVacancy.amount--
        if (beforeObjVacancy.amount == 0) {
          beforeObjDepartments.vacancy = beforeObjDepartments.vacancy.filter((el) => el.id != beforeObjVacancy.id)
        }
      }
    }
    state.items.forEach((el) => {
      if (el.amount === 0) {
        el.department = []
      }
    })
  }
}

export const actions = {
  async getStages({ commit }) {
    try {
      const result = await this.$api.stage.get()
      commit('setItems', result)
    } catch (e) {
      console.error('Ошибка при получении этапов', e)
    }
  }
}

export const getters = {
  stage: (state) => {
    return JSON.parse(JSON.stringify(state.items))
  }
}
