import Study from './Study'
import Work from './Work'
import Duplicate from './Duplicate'
import Sourse from './Sourse'

export default function (el = {}) {
  return {
    id: el.id,
    birthday: el.birthday,
    createdAt: el.created_at,
    currentAddress: el.current_address,
    registrationAddress: el.registration_address,
    email: el.email,
    name: el.full_name,
    note: el.note,
    phone: el.phone,
    employmentType: el.employment_type,
    bio: el.bio,
    job: el.other_job,
    isBlacklist: el.is_in_blacklist,
    isArchived: el.is_archived,
    resume: el.resume,
    source: Sourse(el.source),
    study: el?.study_places?.map((item) => Study(item)) || [],
    work: el?.work_places?.map((item) => Work(item)) || [],
    duplicate: el?.duplicates ? el?.duplicates?.map((item) => Duplicate(item)) : []
  }
}
