import { serializeQueryParams } from './helper'

export default {
  get({ applicant, limit, offset } = {}) {
    return this.$axios.$get(
      `/events/${serializeQueryParams({
        limit,
        offset,
        applicant
      })}`
    )
  }
}
