import generateApi from '~/api'

export default (context, inject) => {
  let refreshTokenPromise = null
  if (context.store.state.user.token?.access) {
    context.$axios.setToken(context.store.state.user.token?.access, 'Token')
  }

  context.app.$axios.onRequest(checkToken)

  context.app.$axios.onError(async (error) => {
    if (error.response?.status === 401) {
      context.redirect(302, '/login/')
      context.$axios.setToken(null, 'Token')

      await context.store.dispatch('user/logout')
    } else {
      return Promise.reject(error)
    }
  })

  async function checkToken(config) {
    // если нет токена - нечего проверять
    if (!context.store.state.user.token?.access) {
      return config
    }

    if (!config.skipAuth && isRefreshTokenExpire()) {
      context.redirect(302, '/login/')
      context.$axios.setToken(null, 'Token')
      await context.store.dispatch('user/logout')
    } else {
      if (!config.skipAuth && isTokenExpire()) {
        try {
          const token = await refreshToken()
          if (!token) {
            throw new Error('Ошибка при обновлении токена')
          } else {
            config.headers.common.Authorization = token.bearer
            context.$axios.setToken(token?.token, 'Token')
            refreshTokenPromise = null
          }
        } catch (e) {
          console.log('Не удалось обновить токен', e)
          context.redirect(302, '/login/')
          context.$axios.setToken(null, 'Token')
          await context.store.dispatch('user/logout')
        }
      }
    }

    return config
  }

  function isRefreshTokenExpire() {
    const isExpire = Date.now() - context.store.state.user.token?.expire >= 1000 * 60 * 60 * 24 // 24 часа
    return isExpire
  }

  function isTokenExpire() {
    const isExpire = Date.now() - context.store.state.user.token?.expire >= 4.5 * 60 * 1000 // 4.5 минуты, 60 секунд, 1000 милисекунд
    const isUser = true // сюда добавь проверку на юзер-дату потом
    return isExpire && isUser
  }

  function refreshToken() {
    return (refreshTokenPromise = refreshTokenPromise ? refreshTokenPromise : requestRefreshToken())
  }

  function requestRefreshToken() {
    return new Promise(async (resolve, reject) => {
      try {
        await context.store.dispatch('user/refreshToken')
        resolve({
          bearer: `Token  ${context.store.state.user.token.access}`,
          token: context.store.state.user.token.access
        })
        await context.store.dispatch('user/getData')
      } catch (e) {
        reject(e)
      }
    })
  }

  inject('api', generateApi(context))
}
