import { serializeQueryParams } from './helper'
import Vacancy from '~/model/Vacancy'
import VacancySerializer from '~/serializer/Vacancy'

export default {
  async get({ search, limit, offset, isOpen, department, city, recruiters, order } = {}) {
    const response = await this.$axios.$get(
      `/vacancies/${serializeQueryParams({
        limit,
        offset,
        search,
        is_open: isOpen,
        department,
        city,
        recruiters,
        order
      })}`
    )
    return {
      count: response.count,
      list: response?.results?.map((el) => Vacancy(VacancySerializer(el))) || []
    }
  },
  async getAsGuest() {
    const response = await this.$axios.$get(`/vacancies/${serializeQueryParams({ limit: 999 })}`, {
      headers: {
        Authorization: null
      },
      skipAuth: true
    })
    return {
      list: response?.results?.map((el) => Vacancy(VacancySerializer(el))) || []
    }
  },
  add({ title, stage, departmentId, cityId, recruiter, isOpen, note, description, salary, employmentType, schedule }) {
    return this.$axios.$post('/vacancies/', {
      title,
      default_stage: stage,
      department_id: departmentId,
      city_id: cityId,
      is_open: isOpen,
      recruiters: recruiter,
      note,
      description,
      salary,
      employment_type: employmentType,
      schedule
    })
  },
  async patch({ id, data }) {
    const response = await this.$axios.$patch(`/vacancies/${id}/`, data)
    return Vacancy(VacancySerializer(response))
  },
  isOpenAll({ items, val } = {}) {
    items.forEach((item) => {
      return this.$axios.$patch(`/vacancies/${item.id}/`, { is_open: val })
    })
  }
}
