//
//
//
//
//
//

import hoverintent from 'hoverintent'

export default {
  name: 'ToggleHover',
  props: {
    event: {
      type: String,
      default: 'hover'
    },
    toggleParentClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      $hoverintent: null,
      isParentActive: false
    }
  },
  mounted() {
    if (this.event === 'hover') {
      this.$hoverintent = hoverintent(this.$el, this.onMouseOver, this.onMouseOut)
    }
  },
  beforeDestroy() {
    if (this.$hoverintent) {
      this.$hoverintent.remove()
    }
  },
  methods: {
    onClick() {
      this.isParentActive = !this.isParentActive
    },
    onMouseOver() {
      this.isParentActive = true
    },
    onMouseOut() {
      this.isParentActive = false
    }
  }
}
