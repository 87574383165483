import Department from './Department'
import Recruiter from './Recruiter'
import City from './City'

export default function (el = {}) {
  return {
    id: el.id,
    stage: el.default_stage,
    department: Department(el.department),
    city: City(el.city),
    employmentType: el.employment_type,
    isOpen: el.is_open,
    note: el.note,
    recruiter: el?.recruiters?.map((item) => Recruiter(item)) || [],
    salary: el.salary,
    schedule: el.schedule,
    name: el.title,
    applicantCount: el.applicants_amount
  }
}
