export const state = () => ({
  token: null,
  data: null
})

export const mutations = {
  async setToken(state, token) {
    state.token = token
    this.$axios.setToken(token?.access, 'Token')
  },
  setData(state, data) {
    state.data = data
  }
}

export const getters = {
  isUser(state) {
    return !!state.data
  }
}

export const actions = {
  async refreshToken({ state, commit }) {
    try {
      this.$axios.setToken(false)
      const result = await this.$api.auth.refreshToken({
        refresh: state.token?.refresh
      })
      commit('setToken', {
        access: result.access,
        refresh: result.refresh,
        expire: Date.now()
      })
    } catch (e) {
      console.error('Ошибка при обновлении токена', e)
    }
  },
  async resetToken({ state, commit }) {
    try {
      const result = await this.$api.auth.resetToken({
        refresh: state.token?.refresh
      })

      commit('setToken', {
        access: result.access,
        refresh: result.refresh,
        expire: Date.now()
      })
    } catch (e) {
      console.error('Ошибка при сбросе токена', e)
      Error('Ошибка при получении токена авторизации')
    }
  },
  async logout({ commit }) {
    try {
      commit('setToken', {
        access: null,
        refresh: null,
        expire: null
      })
      commit('setData', null)
      this.$router.push('/login/')
    } catch (e) {
      console.error('Ошибка при логауте', e)
    }
  },
  async getData({ commit }) {
    try {
      const users = await this.$api.user.me()
      commit('setData', users)
    } catch (e) {
      console.error('Ошибка при логауте', e)
    }
  }
}
