export default function (el = {}) {
  return {
    id: el.id,
    birthday: el.birthday,
    createdAt: el.created_at,
    name: el.full_name,
    lead: el.lead,
    note: el.note,
    job: el.other_job,
    phone: el.phone,
    recruiter: el.recruiter,
    result: el.result,
    source: el.source,
    test: el.test,
    vacancy: el.vacancy
  }
}
