export const state = () => ({
  items: null
})

export const mutations = {
  setiItems(state, data) {
    state.items = data
  }
}

export const actions = {
  async getUsers({ commit }) {
    try {
      const result = await this.$api.user.get()
      commit('setiItems', result)
    } catch (e) {
      console.error('Ошибка при получении рекрутеров', e)
    }
  }
}

export const getters = {
  users: (state) => {
    return JSON.parse(JSON.stringify(state.items))
  }
}
