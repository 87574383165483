import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=400e439e&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=400e439e&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "400e439e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMenu: require('/app/components/HeaderMenu/HeaderMenu.vue').default,UserAuth: require('/app/components/UserAuth/UserAuth.vue').default})
