import { render, staticRenderFns } from "./UserAuth.vue?vue&type=template&id=22c6a9fe&scoped=true&"
import script from "./UserAuth.vue?vue&type=script&lang=js&"
export * from "./UserAuth.vue?vue&type=script&lang=js&"
import style0 from "./UserAuth.vue?vue&type=style&index=0&id=22c6a9fe&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22c6a9fe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Popup: require('/app/components/Popup/Popup.vue').default,ToggleHover: require('/app/components/ToggleHover/ToggleHover.vue').default})
