// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/icon/human.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/icon/vacancies.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".header-menu[data-v-4e34b386]{display:flex;align-items:center}.header-menu__icon[data-v-4e34b386]{width:16px;height:16px;background-position:50% 50%;background-size:16px;background-repeat:no-repeat;background-size:auto 16px;margin-right:7px;flex-shrink:0;display:inline-block;position:relative}.header-menu__icon_human[data-v-4e34b386]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.header-menu__icon_vacancies[data-v-4e34b386]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.header-menu__item[data-v-4e34b386]{position:relative;z-index:4;display:flex;align-items:center;color:#fff;text-decoration:none;padding:9px 0;margin-right:27px}.header-menu__item[data-v-4e34b386]:last-child{margin-right:0}.header-menu__item-link[data-v-4e34b386]{color:#fff;text-decoration:none}.header-menu__item-wrap[data-v-4e34b386]{display:flex;align-items:center}.header-menu__item-wrap_active[data-v-4e34b386],.header-menu__item-wrap_active .header-menu__item-link[data-v-4e34b386]{color:#000}.header-menu__item-wrap_active .popup[data-v-4e34b386]{display:block}", ""]);
// Exports
module.exports = exports;
